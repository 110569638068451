import React, { useState, ChangeEvent } from 'react';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import axios from 'axios';
import './tools.scss';

type CsvData = {
  id: string;
};

type Progress = {
  sent: number;
  remaining: number;
};

type ButtonTexts = {
  button1: string;
  button2: string;
};

const CSVMessageSender: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const [botToken, setBotToken] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [csvData, setCsvData] = useState<CsvData[]>([]);
  const [progress, setProgress] = useState<Progress>({ sent: 0, remaining: 0 });
  const [failedUsers, setFailedUsers] = useState<{ id: string; error: string }[]>([]);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [photoUrl, setPhotoUrl] = useState<string>('');
  const [addButtons, setAddButtons] = useState<boolean>(false);
  const [buttonTexts, setButtonTexts] = useState<ButtonTexts>({ button1: '💵 TOP UP WITH $TON 💵', button2: '🔥 PLAY NOW 🔥' });
  const [inputType, setInputType] = useState<'csv' | 'manual'>('csv');
  const [manualIds, setManualIds] = useState<string>('');

  const handleBotTokenChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('Bot token changed:', e.target.value);
    setBotToken(e.target.value);
  };

  const handleCSVUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log('CSV file selected:', file);
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result: any) => {
          console.log('CSV parsing complete:', result);
          if (result.data.length > 0 && result.data[0].hasOwnProperty('id')) {
            setCsvData(result.data as CsvData[]);
            setProgress({ sent: 0, remaining: result.data.length });
          } else {
            alert('CSV file must contain an "id" column representing Telegram user IDs.');
          }
        },
      });
    }
  };

  const handleSendMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    console.log('Message changed:', e.target.value);
    setMessage(e.target.value);
  };

  const handlePhotoUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('Photo URL changed:', e.target.value);
    setPhotoUrl(e.target.value);
  };

  const handleAddButtonsChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('Add buttons toggled:', e.target.checked);
    setAddButtons(e.target.checked);
  };

  const handleButtonTextChange = (e: ChangeEvent<HTMLInputElement>, button: 'button1' | 'button2') => {
    console.log(`Button text changed for ${button}:`, e.target.value);
    setButtonTexts({ ...buttonTexts, [button]: e.target.value });
  };

  const handleInputTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('Input type changed:', e.target.value);
    setInputType(e.target.value as 'csv' | 'manual');
  };

  const handleManualIdsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    console.log('Manual IDs changed:', e.target.value);
    setManualIds(e.target.value);
  };

  const sendMessages = async () => {
    if (!message && !photoUrl) return;
    setIsSending(true);
    let users: CsvData[] = [];

    if (inputType === 'csv') {
      users = csvData;
    } else if (inputType === 'manual') {
      users = manualIds.split(',').map((id) => ({ id: id.trim() }));
    }

    const total = users.length;
    let failed: { id: string; error: string }[] = [];
    const delay = 1000 / 20; // 20 messages per second

    console.log('Starting to send messages. Total messages:', total);

    for (let i = 0; i < total; i++) {
      await new Promise((resolve) => setTimeout(resolve, delay));

      const user = users[i];
      let requestData: any;
      if (photoUrl) {
        requestData = {
          chat_id: user.id,
          photo: photoUrl,
        };
        if (message) requestData.caption = message;
      } else {
        requestData = {
          chat_id: user.id,
          text: message,
          parse_mode: 'Markdown', // Added parse mode for formatting support
        };
      }

      if (addButtons) {
        requestData.reply_markup = {
          inline_keyboard: [
            [{ text: buttonTexts.button1, url: 'https://t.me/GStoreCBot/store' }],
            [{ text: buttonTexts.button2, url: 'https://t.me/gshake_bot/game' }],
          ],
        };
      }

      try {
        const endpoint = photoUrl ? 'sendPhoto' : 'sendMessage';
        await axios.post(`https://api.telegram.org/bot${botToken}/${endpoint}`, requestData);
        console.log(`Message sent to user ID: ${user.id}`);
      } catch (error: any) {
        console.error(`Failed to send message to user ID: ${user.id}`, error);
        failed.push({ id: user.id, error: error.message || 'Unknown error' });
      }
      setProgress({ sent: i + 1, remaining: total - (i + 1) });
    }

    console.log('Message sending complete. Failed users:', failed);
    setFailedUsers(failed);
    setIsSending(false);
  };

  const exportFailedUsers = () => {
    console.log('Exporting failed users:', failedUsers);
    const csv = Papa.unparse(failedUsers);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'failed_users.csv');
  };

  const handleSubscribe = () => {
    console.log('Subscribe button clicked');
    setStep(2);
  };

  return (
    <div className='tools-wrapper'>
      <h2>Message Sender for Lukas</h2>
      {step === 1 && (
        <div>
          <input
            type="text"
            placeholder="Enter Bot Token"
            value={botToken}
            onChange={handleBotTokenChange}
          />
          <button onClick={handleSubscribe} disabled={!botToken}>Subscribe to Bot</button>
        </div>
      )}
      {step === 2 && (
        <div>
          <textarea
            placeholder="Enter message to send"
            value={message}
            onChange={handleSendMessageChange}
          />
          <br /><br />
          <input
            type="text"
            placeholder="Enter Photo URL (optional)"
            value={photoUrl}
            onChange={handlePhotoUrlChange}
          />
          <br /><br />
          <label>
            <input
              type="checkbox"
              checked={addButtons}
              onChange={handleAddButtonsChange}
            /> Add buttons
          </label>
          {addButtons && (
            <div className='button-options'>
              <label>
                Button 1 Text:
                <input
                  type="text"
                  value={buttonTexts.button1}
                  onChange={(e) => handleButtonTextChange(e, 'button1')}
                />
              </label>
              <label>
                Button 2 Text:
                <input
                  type="text"
                  value={buttonTexts.button2}
                  onChange={(e) => handleButtonTextChange(e, 'button2')}
                />
              </label>
            </div>
          )}
          <br /><br />
          <label>
            <input
              type="radio"
              value="csv"
              checked={inputType === 'csv'}
              onChange={handleInputTypeChange}
            /> Import from CSV
          </label>
          <br/>
          <label>
            <input
              type="radio"
              value="manual"
              checked={inputType === 'manual'}
              onChange={handleInputTypeChange}
            /> Enter Telegram User/Channel IDs Manually
          </label>
          <br /><br />
          {inputType === 'csv' && (
            <input type="file" accept=".csv" onChange={handleCSVUpload} disabled={isSending} />
          )}
          {inputType === 'manual' && (
            <textarea
              placeholder="Enter comma-separated Telegram User/Channel IDs"
              value={manualIds}
              onChange={handleManualIdsChange}
            />
          )}
          <br /><br />
          {(csvData.length > 0 || (inputType === 'manual' && manualIds.trim().length > 0)) && !isSending && (
            <button onClick={sendMessages}>Send Messages</button>
          )}
          <br /><br />
          {isSending && (
            <div>
              <p>Sending messages... Sent: {progress.sent}, Remaining: {progress.remaining}</p>
            </div>
          )}
          {failedUsers.length > 0 && (
            <div>
              <p>{failedUsers.length} messages failed.</p>
              <button onClick={exportFailedUsers}>Export Failed Users</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CSVMessageSender;
